/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";
@media all and (max-width: 1023px) {   

	.wrapper {width: 715px;}  

	header {
		/* .top {
			height: 7px;
			.search {display: none;} 
			.lang {display: none;} 
		}  */
		/* .bot {
			.right_header {display: none;}    
		}
		.toggle_menu {
			display: block; 
		} */


	}

	header .bot .left_header .logobot{ width: 200px; }
	.banner-slider {
		.list-slider {
			.box-caption {
				width: 460px;
				.tittle-capt {padding-left: 20px;}  
			} 
			.wrapper {
				position: absolute; height: 100%; top: 0; left: 50%; padding: 0 0; z-index: 15;
				@include boxSizing(border-box);  
				@include transform(translateX(-50%));
			}
			.box-caption {
				bottom: 0; top: 57%; left: 0; height: auto;  
				@include transform(translateY(-50%)); 
				p {font-size: 16px; line-height: 26px; margin-bottom: 35px;} 
			}
		}
	}
	.banner-top{
		.wrap-box{
			h2{
				font-size: 26px;
			}
		}
	}
	span.tgl {font-size: 11px; margin-bottom: 10px;} 

	.home { 
		span.tgl.wht {font-size: 11px;}
		.tittle {
			 h1 {
			 	&.num {padding-bottom: 35px;}  
			 }
		}
		&.visimisi {
			padding-bottom: 0px;
			.tittle-sec {
				width: 100%;
				h2 {font-size: 30px;} 
				p {font-size: 16px; line-height: 22px;}     
				&:before { 
					width: 65px;   
				}
			} 
			.box-visimisi { 
				margin-bottom: 70px; padding-bottom: 0; 
				&:after {clear:both;display: block;content:""} 
				padding-left: 365px; height: auto;  
				.visi {width: 100%; margin: 0 0 50px 0;}   
				.misi {width: 100%; margin: 0;}  
				.images-visi {width: 265px; height: 360px; bottom: 0;}  
			}  
		}
		&.news-event {
			.box-list {		
				width: 100%; margin: 0 0 16px 0; position: relative; 		
				.box {
					width: 100%; position: relative; height: auto; padding: 0; 	 				
					.sub-box {
						height: auto; margin-right: 0; width: 48.7%; padding: 15px;
						&:first-child {position: absolute; top: -100%; margin-top: -16px; right: 0px;}  
						a {
							h4 {font-size: 20px; line-height: 26px; margin: 10px 0 15px 0; height: 50px;}  
							i.round {left: 15px; font-size: 11px;}
							span{

								&.ico{
									right: 15px; top: 15px; width: 24px; height: 18px; background-size:24px auto;
								}
								&.tgl{  display: inline-block; padding-bottom: 10px; }
							} 
							.loc{ position: relative; } 

						}
					}					 
					&.big {
						width: 48.7%;
						overflow: hidden; position: relative;         
						figure {
							.caption {
								left:0; bottom: 0; width: 100%; padding:15px 15px; @include boxSizing(border-box);
								h5 {font-size: 18px; line-height: 20px;} 
							}
							i.round {left: 15px; font-size: 11px;}
							span.ico {right: 15px; top: 15px; width: 24px; height: 18px; background-size:24px auto;} 
							img{ filter: none; }
						}  
					} 
				}
				&.left {
					.box {
						margin-top: 16px;
						&.big {margin-top: 0;} 
					}
				}
				&.right { 
					.box {
						margin-top: 0px;
						.sub-box {
							&:first-child {}
						}
						&.big {margin-top: 0; position: absolute; bottom: 0%; right: 0px;}

					}
				}
			} 
		}
		&.virtual {
			.caption-virtual {width: 515px; top: 50%; @include transform(translateY(-50%));}  
		}

		&.family { 
			.box-img {
				.list-img {width: 50%; line-height: 0;}   
			}
		}
		&.number {
			.box-top {
				// margin-top: -140px;
				.box-info {
					margin-bottom: 60px;
					.list-info {
						height: 112px; padding: 15px 10px; 
						@include boxSizing(border-box);  
						.isi {
							padding: 0;
							img {right: 0; position: absolute;} 
							i.info {font-size: 13px; left: 0; bottom: 0;}   
							h2 {font-size: 22px; bottom: 0; right: 0;}   
						} 
					} 
				}
			}
			.box-bot {
				padding-bottom: 45px; 
				.tittle-info {
					padding-bottom: 30px;  
					h1 {font-size: 28px;}  
				}
			}
		}
		.map {
			padding-bottom: 100px;  
			#idMap {height: 250px;} 
			.txt-map {
				left: 50%; top: 50%; width: 260px; height: 180px; min-height: 180px; padding: 20px; 
				@include transform(translateX(-50%)); 
			} 
		}

	}

	.slide-overview {
		margin: 0 auto; margin-bottom: 20px;
		.slider-text {
			width: 220px; height: 240px; right: -60px;
			.text {
				padding: 28px;
				h6 {font-size: 13px; margin-bottom: 15px;}   
				h3 {font-size: 18px; line-height: 24px;}  
			} 
			.slick-arrow {
				width: 40px;
				&.slick-prev {right: 40px;}  
			}
		} 
	}
	.wrap-acc {
		.list-acc {
			.grid-acc {
				padding: 12px 20px;
				.tittle-acc {font-size: 16px;} 
				&:before {top:15px;}
				&.down {
					&:before {top:15px;} 
				}
			}
		}
	}
	.list-acc {   
		.content-acc {  
			ul {
				&.academic-acc {    
					li {padding: 12px 20px; font-size: 16px;}        
				}
			}
		}
	}
	
	.banner-std {
		img {display:block; height: 340px; width: 100vw; object-fit: cover;}      
	} 
	.list-acc {
		.grid-acc {
			padding: 15px; 
			&:before {top:17px;}
			.tittle-acc {font-size: 18px;}   
		}
	}

	.top-menu { 
		.left {
			&.breadcrumb { 
				ul {
					li {
						font-size: 12px; margin-right: 10px;   
						&:before {left:-8px;} 
						a { 
							font-weight: 600; 
							&.home {width: 35px;}
						} 
					}
				}
			}
		}
		.right {
			&.submenu {
				span {margin-right: 10px;}  
			}
		}
	}

	.content-std {  
		.inner-content {
			padding: 50px 70px;   
			.infor-collage {
				&.requir {padding: 25px;}    
			}
			ul {
				li {line-height: 24px;}   
			}
			&.filt {padding: 50px 70px;} 
			.contact-infor {
				.list-contact {
					padding: 20px;
					h3 {font-size: 16px; line-height: 20px;} 
					.bot-con {
						margin-top: 40px; 
						h6 {font-size: 12px;}
					}
				}
			}
			.filosofi{
				.logo-big{
					width: 30%;
					.wrap-span{
						> span{
							&.left{ left: 25px; bottom: 50px;  }
							&.top { right: 30px; top: 10px; }
							&.right{ right: 10px; bottom: 15px;  }
							&.active{
								width: 40px; height: 40px;
								.small{ width: 10px; height: 10px; }
							}
							.small{ width: 7px; height:7px;	}
							.medium{ width: 20px; height: 20px;  }
						}
					}
				}
				.accordion{ 
					width: 60%;
				}
			}

		}
		h1 {
			font-size: 22px; padding-bottom: 12px; margin-bottom: 30px; 
			&.left {width: 50%; margin-bottom: 0;} 
		}     
		h2 {
			font-size: 20px; line-height: 24px;
			&.std {line-height: 24px; margin-bottom: 5px;} 
		}  
		h4 {
			&.bld {font-size: 20px;}  
		}
		h5 {
			&.content {line-height: 26px;}    
		}
		p {
			line-height: 26px;
			&.bld {margin-bottom: 10px;}     
			&.list {margin-bottom: 8px;}   
		} 
		table { 
			margin-bottom: 40px;   
			tr {
				td {font-size: 13px; padding: 15px 8px;}      
				&.top {
					td {vertical-align: middle;}  
				}
			}
		}
		span {
			&.line {margin-bottom: 40px; height: 2px;}  
			&.tittl-tgl {font-size: 14px; margin-bottom: 15px;} 
		}
		.wrap-box-admission {
			.list-box {
				width: 48%; margin-right: 4%;
				.box {
					padding: 20px 15px;
					h4 {font-size: 18px;} 
				}
				.grid-email {
					position: relative; padding:0 10px; height: 50px;    
					&:before {display: none;} 
					img {display: none;} 
					span {left: 15px; top: 18px;}   
				}
			}
		}
		
		.form {     
			.row {  
				.field { 
					input[type='text'],input[type='email'],  
			        input[type='file'] { 
			            width: 100%; height: 35px; border: 2px solid #e1e1e1; @include boxSizing(border-box);
			            &.num{
			                padding-left: 50px;    
			            }
			        }
			        select {    
			            width: 100%;     
			        } 
				}
			}
		}

		.list-issue {
			margin:0 70px; 
			.box-issue {
				width: 47.0%; 
				&:nth-child(3n+3) {margin-right: 4%;}
				&:nth-child(2n+2) {margin-right: 0%;}
				.issue {
					padding: 20px;
					h3 {font-size: 18px; padding-bottom: 15px;}    
					h6 {font-size: 13px;} 
				}
			}
		}
		.wrap-btnloadmore {margin-bottom: 0px;}  
		
		.box-wrap-news {
			.list-news {
				.text-news {
					h3 {margin-bottom: 20px;} 
					p {display: none;} 
				}
			}
		}
		.wrap-slider {padding-top: 0;} 

		.wrap-newsdetail { 
			padding: 30px 22px; 
			&:before {left:30px; right: 30px;}  
			.tittle-newsdetail {
				h2 {padding: 0 7px; font-size: 20px; margin-bottom: 20px;} 
			}
			.box-newsdetail {
				.list-newsdetail {
					padding: 0 7px;
					.text {padding: 10px 0;}   
				} 
			}
			
		}

		.wrap-list {
			.list-organi {
				.org {
					.org-left {padding-right: 15px;}
					.org-right {padding-left: 15px;} 
				}
			}
		}

		
	}
	.sub-content {    
		padding-left: 70px; 
		.left-absolute {width: 50px; top: 2px;}     
		.slide-overview { margin : 0 auto; margin-bottom: 20px;}     
		.sign {margin: 55px 0;}  
		.btn-share {left: -30px;} 
		&.rightpadding {
			padding-bottom: 30px;
		}         
	}   
	
	.map.contactus {
		padding: 50px 70px 0 70px; 
		#idMap {height: 450px;} 
		.txt-map {top: 50%; right: 120px; @include transform(translateY(-50%));} 
	}
	
	footer {
		 .top-footer { 
		 	padding: 35px 0; 
		 	.top-info {
		 		.logo {width: 180px; margin-right: 28px;}   
		 		.part {
		 			width: 178px; height: 65px; padding-left: 20px; border-left-width: 1px;   
					h4 {font-size: 13px;}
					p {font-size: 13px; line-height: 16px; margin-bottom: 1px;}  
					&:last-child {margin-right: 0;} 
		 		} 
		 		.ico-sosmed {
		 			padding-top: 30px; 
		 			 a {margin-right: 3px;}  
		 		}
		 	}
		 }
		 .bot-footer {   
		 	padding: 11px 0;  
		 	.desc { 
		 		.nav-bot {
		 			padding-bottom: 2px; padding-top: 5px;  
		 			.item {padding-left: 10px; margin-left: 10px;}      
		 		} 
		 	}
			.copy {
				font-size: 11px;   
				p {margin: 0;}  
			}
		 } 
	}


	.wrap_popup {
		.content_pop {width: 640px; margin: 5% auto;} 
	}

	.content-std{
		.wrap-box-testimoni{
			.list-testimoni{
				.box-testi{
					.text{
						.wrap-p p{height: auto;}
					}
					.bot-testi{
						a{ 
							font-size: 0;
							span{ top: 0; }
						}
					}
				}
			}
		}
		
	}

	.box-circ{
		.list-circ{
			width: 14%;
			.circle{ 
				width: 66px; height: 66px;
				h6{ font-size: 7px; }
			}
		}
	}
	.sub-content {
		.wrap-anthem{
			width: auto;
			.eng{ float: none; width: auto; }
			.ind{ float: none;  width: auto; }
		}
		&.havemrg{
			p{ margin-left: auto; margin-right: auto; }
		}
	}

	.home.instgram{
		.feed{
			margin: 0 -5px;
			a{
				padding: 0 5px;
				&::after{
					left: 5px;
					right: 5px;
				}
			}
		}
	}
	
}